<template>
  <div class="complex-property">
    <div class="description">
      {{ $t('process_editor.element_properties.user_task.has', [fields.length]) }}
    </div>

    <el-button
      class="el-button--square"
      type="primary"
      plain
      icon="el-icon-edit"
      @click="onEdit()"
      size="mini"
    ></el-button>

    <el-dialog
      v-if="editFields"
      :visible.sync="visible"
      :modal="false"
      :close-on-click-modal="false"
      class="custom-dialog__wrapper"
      custom-class="custom-dialog"
      @close="onClose"
      width="50%"
    >
      <template slot="title">
        <div class="el-dialog__title">
          <el-button
            type="primary"
            icon="el-icon-plus"
            class="el-button--square"
            plain
            @click="onAdd()"
            size="mini"
            style="margin-right: 10px;"
          ></el-button>

          {{ $t('process_editor.element_properties.user_task.mapping') }}
        </div>
      </template>

      <el-form class="field-mapping-form custom_scrollbar" ref="form" label-position="top" size="mini">
        <field-mapper-form
          v-for="(item, index) in editFields"
          :key="index"
          :target-registry-id="parseInt(targetRegistryId) || null"
          :task-registry-id="parseInt(taskRegistryId) || null"
          :value="{
            field: item.field,
            type: item.type,
            value: item.value
          }"
          @input="changeField($event, index)"
        >
          <el-button
            class="el-button--square"
            type="danger"
            plain
            icon="el-icon-delete"
            @click="onRemove(index)"
            size="mini"
            style="margin-left: 10px;"
          ></el-button>
        </field-mapper-form>
      </el-form>

      <span slot="footer" class="dialog-footer">
        <el-button @click="onCancel()" size="mini">
          {{ $t('main.button.cancel') }}
        </el-button>

        <el-button type="primary" @click="onAccept()" :disabled="acceptDisabled" size="mini">
          {{ $t('main.button.accept') }}
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import FieldMapperForm
  from '@/services/BPMNEditor/infrastructure/components/PropertiesPanel/PropertyEditors/UserTaskEditor/FieldMapping/FieldMapperForm.vue'

const cloneObject = function (value) {
  return JSON.parse(
    JSON.stringify(value)
  )
}

export default {
  name: 'FieldMapping',

  components: { FieldMapperForm },

  props: {
    taskRegistryId: {},

    targetRegistryId: {},

    extensions: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },

  computed: {
    acceptDisabled () {
      if (!this.editFields || !this.fields) {
        return true
      }

      return JSON.stringify(this.fields) === JSON.stringify(this.editFields)
    }
  },

  mounted () {
    const extensions = this.prepareExtensions(this.extensions)?.mapping

    this.fields = Object.keys(extensions?.fields || {}).map((field) => {
      return {
        field: field,
        type: extensions.fields[field].type,
        value: extensions.fields[field].value
      }
    })
  },

  data () {
    return {
      visible: false,
      fields: [],
      editFields: null,
      historyFields: null
    }
  },

  methods: {
    prepareExtensions (obj) {
      const result = {}

      // For each object path (property key) in the object
      for (const objectPath in obj) {
        // Split path into component parts
        const parts = objectPath.split('.')

        // Create sub-objects along path as needed
        let target = result
        while (parts.length > 1) {
          const part = parts.shift()
          target = target[part] = target[part] || {}
        }

        // Set value at end of path
        target[parts[0]] = obj[objectPath]
      }

      return result
    },

    onAccept () {
      this.fields = cloneObject(this.editFields)
      this.emitChanges()
    },

    onEdit () {
      this.visible = true
      this.editFields = cloneObject(this.fields)
      this.historyFields = cloneObject(this.fields)
    },

    onClose () {
      this.visible = false
      this.editFields = null
      this.historyFields = null
    },

    onCancel () {
      this.visible = false
      this.fields = cloneObject(this.historyFields)
      this.editFields = null
      this.historyFields = null
    },

    onAdd () {
      if (!this.editFields || !Array.isArray(this.editFields)) {
        return
      }

      this.editFields.push({
        field: null,
        type: null
      })
    },

    onRemove (index) {
      if (!this.editFields || !Array.isArray(this.editFields)) {
        return
      }

      this.editFields.splice(index, 1)
    },

    changeField (data, index) {
      this.$set(this.editFields, index, data)
    },

    emitChanges () {
      this.$emit('remove-extension', { filter: (_) => _.indexOf('mapping.fields.') === 0 })
      this.fields.forEach((item) => {
        if (item.field) {
          if (item.type) {
            this.$emit('change-extension', { name: `mapping.fields.${item.field}.type`, value: item.type })
          }
          if (item.value) {
            this.$emit('change-extension', { name: `mapping.fields.${item.field}.value`, value: item.value })
          }
        }
      })
    }
  }
}
</script>
